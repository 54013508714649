@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply h-full;
  }

  body {
    @apply font-mulish w-full h-full;
  }

  #root {
    @apply w-full h-full;
  }

  *::-webkit-scrollbar {
    @apply border-none h-2 w-2;
  }

  *::-webkit-scrollbar-thumb {
    @apply rounded-xl bg-blue-admin;
  }

  .sideMenu {
    @apply text-blue-admin flex items-center space-x-4  pl-4 rounded;
  }

  .sideMenuActive {
    @apply text-white-admin  flex items-center space-x-4 py-4 pl-4 rounded-lg w-[216px]  bg-red-admin;
  }

  .sideMenuActive img {
    @apply invert;
  }

  .navigationActive {
    @apply text-white bg-red-admin py-3  rounded-xl text-sm px-4 text-center;
  }
  .navigation {
    @apply text-blue-admin text-sm font-semibold border border-gray-admin  py-3 rounded-xl px-4 text-center bg-white-admin;
  }
  .inputFocus {
    @apply focus:outline-none focus:border-blue-admin focus:ring-1 focus:ring-blue-admin;
  }
  .checkedFocus {
    @apply checked:bg-orange-clim text-orange-clim focus:ring-orange-clim;
  }
  .input_error {
    @apply py-3 pl-5 text-sm border rounded-lg border-red-error focus:outline-none focus:border-red-error focus:ring-1 focus:ring-red-error;
  }

  /* components table style */
  .componentTable {
    @apply w-full overflow-x-auto  text-blue-admin;
  }

  .componentTable table {
    @apply w-full overflow-hidden;
  }

  .componentTable table thead th {
    @apply text-start pl-2 p-3  first:rounded-l bg-red-admin text-white-admin last:rounded-r text-base font-semibold min-w-[180px];
  }

  .componentTable tbody tr {
    @apply h-16 border-b text-blue-admin border-gray-admin px-2 hover:bg-gray-sidebar text-sm font-medium;
  }

  .componentTable tbody td {
    @apply pl-2;
  }

  /* btn logout side*/
  .btnLogOut {
    @apply md:hidden;
  }

  /* Dashboard Home */
  .card-dashboard {
    @apply border  border-gray-admin w-full md:w-fit p-5 space-y-5 rounded-lg font-mulish text-blue-admin m-2;
  }

  /* List Drivers hover initial  */
  .hoverInitial {
    @apply bg-blue-admin rounded-full text-center text-white-admin relative h-8 w-8 leading-8;
  }
  .hoverInitial > span {
    @apply absolute top-0 left-12 text-blue-admin opacity-0 transition-opacity duration-300;
  }
  .hoverInitial:hover > span {
    @apply opacity-100;
  }
  /* ------------ */
  .hoverInitial-bottom {
    @apply bg-blue-admin rounded-full text-center text-white-admin relative h-8 w-8 leading-8;
  }
  .hoverInitial-bottom > span {
    @apply absolute top-8 -left-20 text-blue-admin opacity-0 transition-opacity duration-300 w-40;
    text-shadow: 1px 1px 2px #5555;
  }
  .hoverInitial-bottom:hover > span {
    @apply opacity-100 overflow-visible;
  }
  /* ------------ */
  .hoverInitial-top {
    @apply bg-blue-admin rounded-full text-center text-white-admin relative h-8 w-8 leading-8;
  }
  .hoverInitial-top > span {
    @apply absolute -top-6 -left-20 text-blue-admin opacity-0 transition-opacity duration-300 w-40;
    text-shadow: 1px 1px 2px #5555;
  }
  .hoverInitial-top:hover > span {
    @apply opacity-100 overflow-visible;
  }
  /* ------------ */

  @media (max-width: 767px) {
    .componentTable tbody td {
      @apply min-w-[180px];
    }
  }

  /* --------------------- */
}
